<template class="layout-toolbar">
  <v-app-bar app :dense="false">
    <v-spacer />
    <v-spacer />
    <v-spacer />
    <v-spacer />
    <v-spacer />
    <!-- <breadcrumbs /> -->
    <slot name="widget-header-action">
      <v-checkbox
        class="mt-5 pr-4"
        label="Set as default"
        :disabled="disabled || isMapRoute"
        v-model="defaultCustomer"
        @click="setDefaultCustomer()"
      ></v-checkbox>
      <div
        v-if="
          currentRoute !== '/audit-tool' && currentRoute !== '/data-integrity-report-dashboard'
          && currentRoute !== '/data-integrity-report'
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" class="mr-4" v-on="on" v-bind="attrs"
              >mdi-information</v-icon
            >
          </template>
          <span>Page content is driven based on the company selected.</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-4 ml-0"
              dark
              v-bind="attrs"
              v-on="on"
              color="#E1AD01"
              >mdi-alert</v-icon
            >
          </template>
          <span>Company controls are managed seperately on this page.</span>
        </v-tooltip>
      </div>
      <!-- <MapCompanyIcon></MapCompanyIcon> -->
      <slot name="widget-header-action">
        <v-autocomplete
          :menu-props="{ auto: true, bottom: true, 'offset-y': true }"
          autocomplete="off"
          outlined
          dense
          :disabled="isMapRoute"
          class="inputField company-dropdown"
          :items="parentcompaniesList"
          v-model="companySelected"
          return-object
          item-text="name"
          item-id="id"
          item-value="id"
          label="Customers"
          align-end
          auto
          @change="updateCompany"
        ></v-autocomplete>
      </slot>
    </slot>
    <div class="d-flex align-center" v-if="!isCustomer && roles!='User'">
      <CustomerDialog :isCustombtn="true" />
      <notification />
    </div>
    <profile />
  </v-app-bar>
</template>
<style scoped>
/* .theme--light.v-app-bar.v-toolbar.v-sheet{
    font-family: "roboto", sans-serif;
    background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
} */
.select-type-report {
  width: 150px !important;
}
.btn-align {
  margin-right: 25px;
  font-size: 12px !important;
  text-transform: capitalize;
}
.plus-icon {
  font-size: 12px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.align-right {
  text-align: right;
}
.textspacing {
  margin-top: 0px;
}
.company-dropdown {
  height: 40px;
  max-width: 250px;
}
</style>
<script>
import { mapGetters } from "vuex";
// import Breadcrumbs from "../widget/AppBreadcrumbs.vue";
import Profile from "../widget/AppProfile.vue";
import Notification from "../widget/AppNotification.vue";
import { hasAccess } from "../../config/admin.route.config";
import rules from "../../utils/rules";
import api from "@/api/api.service";
import companyapi from "@/api/company.service";
import userService from "@/api/user.service";
import EventBus from "../../utils/EventBus";
// import Customer from "@/components/Common/Customer.vue";
import CustomerDialog from "@/components/Common/CustomerDialog";
// import EventBus from "../utils/EventBus";
export default {
  name: "TheLayoutToolbar",
  components: {
    // Breadcrumbs,
    Profile,
    Notification,
    CustomerDialog
  },
  data: () => ({
    reportitems: [{ name: "Report" }, { name: "Integration" }],
    // reportvalue: "",
    repValue: "",
    NewReports: {},
    userdialog: false,
    loading: false,
    valid: true,
    details: "",
    rules: rules,
    // companyName: "",
    trackunitToken: "",
    TrackunitTokenError: "",
    name: "",
    email: "",
    phone: "",
    trackunitnumber: "",
    clientId: "",
    clientSecret: "",
    userName: "",
    password: "",
    showclientSecret: false,
    showpassword: false,
    userRole: "",
    isAccess: {
      reportAbug: true,
      reportRequest: true
    },
    openedPanel: null,
    isCustomer: false,
    parentcompaniesList: [],
    companySelected: {},
    defaultCustomer: true,
    disabled: true,
    layouttoolbarKey: 0,
    currentRoute: null,
    isMapRoute: false
  }),
  watch: {
    deep: true,
    immediate: true,
    $route: function(from) {
      this.currentRoute = from.path;
      if (
         this.currentRoute == "/audit-tool" ||
        this.currentRoute == "/data-integrity-report-dashboard" ||
        this.currentRoute == "/data-integrity-report"
      ) {
        this.isMapRoute = true;
      } else {
        this.isMapRoute = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      "toolbarDense",
      "navbarShow",
      "roles",
      "reportvalue",
      "reportId",
      "ToolsapiToken",
      "companyName"
    ]),
    toggleNavbarIcon() {
      return this.navbarShow
        ? "mdi-format-indent-decrease"
        : "mdi-format-indent-increase";
    }
  },
  created() {
    EventBus.$on("customer-got-triggered", () => {
      (this.rules = rules), (this.userdialog = true);
    });
  },
  mounted() {
    this.userId = this.$store.getters.userId;
    this.isCustomer = this.roles == "Customer" ? true : false;
    this.isAccess = hasAccess.dashboard[this.roles]
      ? hasAccess.dashboard[this.roles]
      : this.isAccess;
    this.CompanyList();
    //Check if reportID exists in store
    if (this.$store.getters.reportId != "") {
      //set the dropdown value to the store reportID.
      this.companySelected.id = this.$store.getters.reportId;
      //check if dropdown change value is default company value and enable checkbox based on that
      if (this.companySelected.id != this.$store.getters.referenceId) {
        this.disabled = false;
        this.defaultCustomer = false;
      } else {
        //disable checkbox as it is a default company
        this.disabled = true;
        this.defaultCustomer = true;
      }
    }
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch("NavbarToggle");
    },
    async CompanyList() {
      try {
        let _this = this;
        let response = await companyapi.getCompanies();
        if (response) {
          if (this.roles == "User") {
            _this.parentcompaniesList = response.data.filter(
              (c) => _this.$store.getters.customerIds.includes(c.id)
            );
          } else {
            _this.parentcompaniesList = response.data;
          }

          //set first item in dropdown in case if user doesnot have default customer
          if (
            (this.$store.getters.referenceId == "" ||
              this.$store.getters.referenceId == null ||
              this.$store.getters.referenceId == undefined) &&
            (this.$store.getters.reportId == "" ||
              this.$store.getters.reportId == null ||
              this.$store.getters.reportId == undefined)
          ) {
            _this.companySelected.id = _this.parentcompaniesList[0].id;
            _this.companySelected.name = _this.parentcompaniesList[0].name;

            this.$store.dispatch("setReportId", this.companySelected.id);
            this.$store.dispatch("setCompanyName", this.companySelected.name);

            //enable checkbox
            this.disabled = false;
            this.defaultCustomer = false;
          }
        }
      } catch (error) {
        //this.$toast.error("Unable to load the data" + error);
      }
    },
    updateCompany() {
      //Update CRID to Store
      this.$store.dispatch("setReportId", this.companySelected.id);
      this.$store.dispatch("setCompanyName", this.companySelected.name);

      if (
        this.$router.currentRoute.path == "/" ||
        this.$router.currentRoute.path == "/customers" ||
        this.$router.currentRoute.path == "/customerreports" ||
        // this.$router.currentRoute.path == "/deployment" ||
        this.$router.currentRoute.path == "/appsheettools/asset_list_upload" ||
        this.$router.currentRoute.path ==
          "/appsheettools/service_list_upload" ||
      this.$router.currentRoute.path == "/field_tool_management"||
        // this.$router.currentRoute.path == "/supporttools" ||
        this.$router.currentRoute.path == "/group-assignment-tool" ||
        this.$router.currentRoute.path == "/hours-update-tool" ||
        this.$router.currentRoute.path == "/metadata-update-tool" ||
         this.$router.currentRoute.path == "/certify-install"
       
      ) {
        this.$router.go();
      }
    },
    async SaveOnBoardingReport() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let payload = {
          userId: this.userId,
          companyName: this.companyName,
          trackUnitToken: this.trackunitToken,
          trackUnitAccountNumber: this.trackunitnumber,
          name: this.name,
          email: this.email,
          phone: this.phone,
          // details: this.details,
          iris_ClientID: this.clientId,
          iris_ClientSecret: this.clientSecret,
          iris_Username: this.userName,
          iris_Password: this.password
        };
        try {
          let apiResponse = await api.createCustomerOnboarding(payload);
          if (apiResponse) {
            this.loading = false;
            this.userdialog = false;
            this.$refs.form.reset();
            this.$toast.success(apiResponse.systemMessage);

            // if (this.$route.name == "CustomerOnboardingList") {
            //   this.$router.go();
            // } else {
            //   this.$router
            //     .push({
            //       path: "/customer-onboarding/list",
            //     })
            //     .catch(() => {});
            // }
          }
        } catch (err) {
          this.loading = false;
          if (err.response.status == 400) {
            this.TrackunitTokenError = err.response.data;
            this.$toast.error(err.response.data);
          } else this.$toast.error("Unable to create customer" + err);
        }
      }
    },
    ClearDialog() {
      this.openedPanel = null;
      this.userdialog = false;
      this.$refs.form.reset();
      (this.companyName = ""),
        (this.trackunitToken = ""),
        (this.name = ""),
        (this.email = ""),
        (this.phone = ""),
        (this.trackunitnumber = "");
      this.clientId = "";
      this.clientSecret = "";
      this.userName = "";
      this.password = "";
    },
    async setDefaultCustomer() {
      let payload = {
        userId: this.$store.getters.userId,
        customerId: this.companySelected.id
      };
      try {
        let apiResult = await userService.setdefaultCustomer(payload);
        if (apiResult) {
          this.disabled = true;
          this.$toast.success(apiResult.systemMessage);
        }
      } catch (err) {
        this.$toast.error("Unable to save as default customer");
      }
    }
  }
};
</script>
