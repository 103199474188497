
import axios from "../api/axios";

export default {
  async execute(method, resource, data) {
    return axios.companyEndPoint({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data;
    });
  },
  getOEM(){
    return this.execute("get", `/Company/basic/oem`);
  },
  getCompanies() {
    return this.execute("get", `/Company/basic`);
  },
  createCustomerOnboarding(payload) {
    return this.execute("post", `/Company`, payload);
  },
  getCustomerbyId(id){
    return this.execute("get", `/Company/${id}`);
  },
  historyUpdate(companyid ,duration){
    return this.execute("put", `/Company/${companyid}/triggerhistorydata?duration=${duration}`);
  },
  updateReportsCustomer(companyid , payload){
    return this.execute("put", `/Company/${companyid}` ,payload);
 }
}